<template>
	<ConfirmDialog></ConfirmDialog>
	
	<div class="grid p-fluid">
		<div class="col-12">
			<div class="card card-w-title">
				<Menubar :model="nestedMenuitems" :popup="true" :key="menuKey">
					<template #end>
						<div class="flex justify-content-end flex-wrap card-container green-container">
							<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
								Hedefler
							</div>
							
							<div class="flex align-items-center justify-content-center m-2">
								<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)" />
							</div>
						</div>
					</template>
				</Menubar>
			</div>
		</div>
	</div>
	<div class="p-grid" style="height: 100%">
		<pre v-if="false">{{ mainData }}</pre>
		<div class="p-col-12 p-md-12" style="height: 100%">
			<div class="card p-fluid" style="height: 100%">
				<DataTable
					:value="mainData"
					:rowHover="true"
					:resizableColumns="true"
					columnResizeMode="fit"
					scrollDirection="horizontal"
					:show-gridlines="true"
					:striped-rows="true"
					:scrollable="true"
					:totalRecords="totalRecords"
					:loading="loading"
					v-model:filters="filters"
					:globalFilterFields="['global', 'bolge', 'marka']">
					<template #empty>
						Kayıt Bulunamadı.
					</template>
					<template #loading>
						Veriler yükleniyor, lütfen bekleyiniz...
					</template>
					<template #header>
						<div class="flex justify-content-between">
							<div>
								<div class="flex flex-wrap card-container green-container">
									<div class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl">
										<Button type="button" icon="pi pi-refresh" label="Yenile" class="p-button-outlined" @click="onLoad()" />
									</div>
									<div class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl">
										<Button type="button" icon="pi pi-angle-down" :label="selectedYear" class="p-button-outlined" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"/>
										<Menu id="overlay_menu" ref="menu" :model="menu_items" :popup="true" />
									</div>
								</div>
							</div>
							<div>
								<span class="p-input-icon-left">
									<i class="pi pi-search" />
									<InputText v-model="filters['global'].value" placeholder="Anahtar kelime ile ara" />
								</span>
							</div>
						</div>
					</template>
					<Column field="bolge" header="Bölge"></Column>
					<Column field="marka" header="Marka"></Column>
					<Column field="hedefAdet" header="Hedef Adet"></Column>
					<Column field="gerceklesenAdet" header="Gerçekleşen Adet"></Column>
					<Column header="Adet Gerçekleşme Oranı">
						<template #body="slotProps">
							<Badge :value="oranHesapla(slotProps.data.gerceklesenAdet, slotProps.data.hedefAdet)" size="large" severity="success"></Badge>
						</template>
					</Column>

					<Column field="hedefCiro" header="Hedef Ciro">
						<template #body="slotProps">
							<span>{{formatNumber(slotProps.data.hedefCiro) }} </span>
						</template>
					</Column>
					<Column field="gerceklesenCiro" header="Gerçekleşen Ciro">
						<template #body="slotProps">
							<span>{{formatNumber(slotProps.data.gerceklesenCiro) }} </span>
						</template>
					</Column>
					<Column header="Ciro Gerçekleşme Oranı">
						<template #body="slotProps">
							<Badge :value="oranHesapla(slotProps.data.gerceklesenCiro, slotProps.data.hedefCiro)" size="large" severity="success"></Badge>
						</template>
					</Column>
				</DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import CrmService from "../service/CrmService";
import { FilterMatchMode} from 'primevue/api';
import user from '../store/user';

export default {
	data() {
		return {
			loading: true,
			crmService: null,
			
			nestedMenuitems: [
				{
					label: "Hedef Analizi 2023",
					command: () => { this.GotoReport("SatisHedefleri", "yil"); },
					icon: "pi pi-fw pi-print"
				},
				{
					label: "Hedef Analizi 2024",
					command: () => { this.GotoReport2("SatisHedefleri2"); },
					icon: "pi pi-fw pi-print"
				},
			],
			
			mainData: [],
			filters: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			},

			menu_items:[],
			items: [
                { label: 'New', icon: 'pi pi-plus' },
                { label: 'Search', icon: 'pi pi-search' }
            ],
			selectedYear: '2024',
		}
	},
	created() {
		this.crmService = new CrmService();
		if (this.profileData) {
			const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Hedefler');
			if (yetkisiVarmi == false) {
				this.$router.replace({ name: 'accessdenied' });
			}
			else {
				const years = [2022, 2023, 2024];
				for (let i = 0; i < years.length; i++) {
					this.menu_items.push({
                        label: years[i],
                        icon: 'pi pi-bars',
                        command: () => {
                            this.selectedYear = years[i];
                            this.onLoad();
                        }
                    });
				}

				this.onLoad();
			}
		}
	},
	methods: {
		onLoad(){
			this.loading = true;
			this.crmService.getHedeflerAll(this.selectedYear)
			.then(data => {
				this.mainData = data.jsonData;
				this.loading = false;
			});
		},
		toggle(event) {
            this.$refs.menu.toggle(event);
        },
		formatNumber(value) {
            debugger;
            if (value != undefined) {
                return value.toLocaleString("tr-TR");
            }
        },
		oranHesapla(gerceklesen, hedef){
			const oran = (gerceklesen / hedef) * 100;
			return parseInt(oran) + '%';
		},
		onRowSelect(data) {
			debugger;
			
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'bm_yedekparcateklifsiparis', params: { id: data.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		GotoReport(Name, ParamName){
			const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name + "&" + ParamName + "=2023";
			window.open(reportUrl, 'asd', '_blank');
		},
		GotoReport2(Name){
			const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name;
			window.open(reportUrl, 'asd2', '_blank');
		},
	},
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		},
	}
}
</script>

<style lang="scss" scoped>

</style>
